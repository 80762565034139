import React from 'react';
import { createRoot } from 'react-dom/client';

const Containers = React.lazy(() => import('src/containers'));

const Start = React.memo((props) => {
    return (
        <React.Suspense fallback={null}>
            <Containers />
        </React.Suspense>
    );
});

// REDIRECT TO HTTPS IF HTTP.
if (['http:','http'].includes(window.location.protocol)) window.location.href = `https://new.astrabackflow.com` + window.location.pathname;

// UPDATED TO REACT 18.
document.addEventListener('DOMContentLoaded', (e) => {
    // START BUILDING PAGE.
    const container = document.getElementById('react');
    const root = createRoot(container);
    root.render(
        <React.StrictMode>
            <Start />
        </React.StrictMode>
    );
});